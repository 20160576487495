<template>
	<el-card class="box-card">
		<div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				
				{{parseInt(this.$route.query.titletype)|typename}}
			</h3>
		</div>
		<div style="width: 60%;">
			<el-form ref="form" :model="form" label-width="80px">
				<!-- <el-form-item label="选择课程">
					<span style="color: #0275D8;" @click="dialogVisible = true">{{name==''?'选择课程':name}}</span>
				</el-form-item> -->
				<el-form-item label="名称">
					<el-input  :disabled="this.$route.query.titletype==1?true:false" v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="描述">
					<el-input :disabled="this.$route.query.titletype==1?true:false"  v-model="form.depict"></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input :disabled="this.$route.query.titletype==1?true:false"  v-model="form.sort"></el-input>
				</el-form-item>
			
				<el-form-item label="缩略图">
					<el-upload
						:disabled="this.$route.query.titletype==1?true:false"
						class="avatar-uploader"
						:action="serverUrl"
						name='image'
						:show-file-list="false"
						:on-success="handleAvatarSuccess"
						:headers="header" 
						:data="data"
						:before-upload="beforeAvatarUpload">
						<img v-if="form.thumb_url" :src="imgurl+ form.thumb_url" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<!-- <el-upload class="avatar-uploader-editor" :action="serverUrl" name="image" :headers="header" :data="data" :show-file-list="false"
					:on-success="uploadSuccess" :before-upload="be_up" :on-error="uploadError"> </el-upload> -->
				</el-form-item>
			<!-- <el-form-item label="课程说明">
				<el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.course_description"></el-input>
				</el-form-item> -->

				<el-form-item v-if="this.$route.query.type==2" label="视频">
					<!-- <el-upload
						:disabled="this.$route.query.titletype==1?true:false"
						class="avatar-uploader"
						:action="serverUrl"
						name='image'
						:show-file-list="false"
						:on-success="handleAvatarSuccess1"
						:headers="header" 
						:data="data"
						:before-upload="beforeAvatarUpload">
						<video style="width: 178px;"  v-if="form.video_url" :src="form.video_url" controls="controls">
						</video>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload> -->
					<!-- <yl-upload
						:disabled="this.$route.query.titletype==1?true:false"
						:action="up_url"
						:data="chunkData"
						:headers="headers"
						:limit="1"
						:on-success="handleSuccess"
						:chunk-size="1024 * 1024 * 20"
						:thread="1"
						:before-upload="before_up_video"
						:on-remove="on_remove"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">视频大小不超过500M</div>
					</yl-upload> -->
					<el-upload
						:disabled="this.$route.query.titletype==1?true:false"
					  :http-request="postImage"
					  :thread="1"
					  :on-remove="on_remove"
					>
					  <el-button size="small" type="primary">点击上传</el-button>
					  <div slot="tip" class="el-upload__tip">最大只能上传5G</div>
					</el-upload>
					<div>
						<el-progress v-show="isProgressVis" :percentage="percentage" v-if="percentage" :color="customColor"></el-progress>
					  <!-- el-progress :text-inside="true" :stroke-width="5" v-if="percentage" :percentage="percentage"
					               v-show="isProgressVis"></el-progress> -->
					</div>
					<video controls="controls" style="width:178px; height: 178px;" v-if="form.video_url" :src="imgurl+ form.video_url"></video>
					<!-- video_url -->
				</el-form-item> 

				<el-form-item v-if="this.$route.query.type==2" label="音频">
					<el-upload
						:disabled="this.$route.query.titletype==1?true:false"
						class="avatar-uploader"                        
						:action="serverUrl"
						name='image'    
						:show-file-list="false"
						:on-success="handleAvatarSuccess2"
						:headers="header" 
						:data="data"
						:before-upload="beforeAvatarUpload">
						<audio  v-if="form.audio_url" :src="imgurl+ form.audio_url" controls="controls">
						</audio>
						<!-- <img v-if="form.audio_url" :src="form.audio_url" class="avatar"> -->
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>

				<el-form-item  v-if="this.$route.query.type==1" label="图文信息">
					<div class="edit_container" style="height: 400px; position: relative;">
						<editor :content="content" @Editor="Editor"></editor>
					</div>	
				</el-form-item>

				<el-form-item v-if="this.$route.query.titletype!=1">
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<!-- <el-button>取消</el-button> -->
				</el-form-item>

			</el-form>
		</div>

		<el-dialog
		  title="选择课程"
		  :visible.sync="dialogVisible"
		  width="30%">
		  <el-radio @change="change"  v-for="(item,index) in goods_list" :key="index" v-model="radio" :label="index">{{item.name}}</el-radio>
		  <!-- <div>{{item.name}}</div> -->
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>

	</el-card>
</template>	
<script>
	// editor
	import {baseURL} from "@/api/https.js";
	import url from "../../../vue.config.js"
	import editor from '../../components/editor/index.vue'
	import YlUpload from '../../components/upload'
	import {ACCEPT_CONFIG} from '../../../bus/config.js'
	import {getSystemsettings} from '../../api/getSystemsetting.js'
	import OSS from 'ali-oss'
	import {
	  classifyList,
	  CourseAdd,
	  CourseList,
	  addNewsCategory,
	  findNewsCateDetail,
	  delNewsCate,
	  content_Save,
	  updataImg,
	  newsSave
	} from "@/api/api.js";
	// import UpImg from "@/components/upImg.vue";
	export default {
		components: {
			editor,
			YlUpload
			 // UpImg 
		},
		data() {
			return {
				isProgressVis: false,
				percentage: 0,
				fileLoading:false,
				header:{AdminToken:localStorage.getItem('admin_token')},
				headers:{admintoken:localStorage.getItem('admin_token')},
				serverUrl:baseURL+'/backend/uploadsfile/upload_image', //上传的图片服务器地址
				up_url:baseURL + '/backend/uploadsfile/upload_image',
				data:{
					type:'addnews'
				},
				name:'',
				length:'',
				dialogVisible:false,
				type:0,
				goods_list:[],
				imageUrl:'',
				radio:-1,
				optionProps: {
				  value: "id",
				  label: "name",
				  children: "children",
				  checkStrictly: true,
				},
				options:[],
				content:'',
				imgurl:'',
				form: {
					course_id:'',
					sort:'',
					name:'',
					depict:'',
					thumb_url:'',
					video_url:'',
					audio_url:'',
					img_url:'',
					content:'',
					duration:'',
				}
			}
		},

		mounted() {
			
			getSystemsettings.then(res=>{
				this.imgurl=res
			})
			console.log(baseURL,'sssssssssssss')
			console.log(url.devServer.proxy)
			this.getlist()
			console.log(this.$route.query.type)
			if(this.$route.query.row){
				this.form=JSON.parse(this.$route.query.row)
				this.form.id=JSON.parse(this.$route.query.row).id
				this.content=JSON.parse(this.$route.query.row).content
				console.log(this.content)
			}
			this.form.course_id=this.$route.query.id
			// this.type=this.$route.query.type
		},
		filters:{
			typename(val){
			    let chargename = '';
			    switch (val) {
			        case 0:
			            chargename = '添加内容';
			            break;
			        case 1:
			            chargename = '查看内容';
			            break;
			        case 2:
			            chargename = '编辑内容';
			            break;
			    }
			    return chargename;
			},
		},
		methods: {
			async postImage(option) {
				var size=5*1024*1024*1024
				console.log(option.file.type.split('/')[1])
				if(ACCEPT_CONFIG.video.indexOf(option.file.type.split('/')[1])==-1){
					this.$message.error('只能上传mp4,rmvb,mkv,wmv,flv');
					return false
				}
				if(option.file.size>size){
					this.$message.error('最大只能上传5G');
					return false
				}
				var time=new Date()
				var y=time.getFullYear()
				var m=time.getMonth()+1
				if(m<10){
					m=0+''+m
				}else{
					m=m
				}
				var d=time.getDate()
				var t=y+''+m+''+d
				console.log(time.getFullYear())
				var date=Date.parse(new Date())
			    try {
			      //构建上传文件参数
			      this.fileLoading = true
			      let self = this
					let client = new OSS({
					  region: 'oss-cn-beijing',
					  accessKeyId: 'LTAI5tNiviqpbTa8qed4knVQ',
					  accessKeySecret: 'Jui4XJUxmNN9f9iX68FjyUA6w7RSl4',
					  bucket: 'storeqnkj',
					  
					})
					let partSize={
						partSize:5*1024*1024
					}
			      let objectName = '/addnews/'+t+'/'+escape(date)+'.'+option.file.name.split('.')[option.file.name.split('.').length-1]
			      // 分片上传文件
			      let result = await client.multipartUpload(objectName, option.file, {
				     //进度条更新
					 progress: async function(p) {
			          self.percentage = parseInt(p * 100)
			          self.isProgressVis = true
			        }
			      })
				  // if(result.res.requestUrls[0])
			      console.log(result.res.requestUrls[0].split('?')[0])
					this.form.video_url=result.res.requestUrls[0].split('?')[0].split('.com/')[1]
					self.isProgressVis = false
			  } catch (error) {
				  console.log(error)
			    this.$message.error(error.message)
			  }
			},
			// on_progress(){},

			change(e){
				console.log(this.goods_list[e])
				this.type=this.goods_list[e].type
				this.name=this.goods_list[e].name
				this.form.course_id=this.goods_list[e].id
			},
			
			handleAvatarSuccess(res,file){
				console.log(res,file)
				// this.imageUrl=res.data.imgurl
				this.form.thumb_url=res.data.imgurl
			},

			on_progress(event, file, fileList){
				console.log(event.percent)
				if(event.percent==100){
					this.length='上传中'+' '+'99.9%'
				}else{
					this.length='上传中'+' '+event.percent+'%'
				}
			},

			handleAvatarSuccess1(res,file){
				this.form.video_url=res.data.imgurl
				this.length='上传完成 100%'
				setTimeout(()=>{
					this.length=''
				},500)
			},

			handleAvatarSuccess2(res,file){
				this.form.audio_url=res.data.imgurl
			},

			beforeAvatarUpload(file){
				console.log(file)
				  let videoUrl = URL.createObjectURL(file);
				  let audioElement = new Audio(videoUrl);
				  audioElement.addEventListener("loadedmetadata", () => {
					let duration = audioElement.duration; //时长为秒，小数，182.36
					console.log(duration)
					this.form.duration=duration
				  });
			},

			Editor(e){
				this.form.content=e
			},

			setclass(e){
				console.log(e)
				console.log(this.form.cid)
				this.form.cid=this.form.cid[this.form.cid.length-1]
				// for(var i=0;i<this.form.cid.length; i++){
					
				// }
			},

			onSubmit() {
				console.log(this.form)
				console.log('submit!');
				// console.log(editor)
				this.newsSave()
			},

			goBack() {
				console.log('go back');
				this.$router.back(-1)
			},

			// 添加
			async newsSave() {
			    const { data } = await content_Save(this.form);
			    if (data.code != 200) return this.$message.error(data.data);
				this.$message({
				  message: data.data,
				  type: 'success'
				});
				setTimeout(()=>{
					this.$router.back(-1)
				},500)
				
			    // this.list = data.data.classify_list;
				// this.options=data.data.classify_list;
			},

			// 获取新闻分类列表
			async getlist(keyword) {
			    const { data } = await CourseList({keyword:keyword});
				console.log(data)
			    if (data.code != 200) return this.$message.error(data.data);
			    this.goods_list = data.data.goods_list.data;
			},


			chunkData(option){
				return{
					size: option.fileSize, // 总文件大小
					chunks: option.chunkTotal, // 所有切片数量
					chunk: option.chunkIndex,// 当前切片下标
					md5: option.chunkHash, // 单个切片hash
					filename: option.fileName, // 文件名
					fileHash: option.fileHash // 整个文件hash
				}
			},
			before_up_video(file){
				var size=500*1024*1024
				console.log(file.type.split('/')[1])
				if(ACCEPT_CONFIG.video.indexOf(file.type.split('/')[1])==-1){
					this.$message.error('只能上传mp4,rmvb,mkv,wmv,flv');
					return false
				}
				console.log(file.size)
				if(file.size>size){
					this.$message.error('最大只能上传500兆');
					return false
				}
				
			},
			handleSuccess(response, file, fileList) {
				//文件上传成功
				console.log(response, file, fileList);
				if(response[response.length-1].code==200){
					this.form.video_url=response[response.length-1].data.imgurl
				}
				// form.video_url
			},
			on_remove(e){
				console.log(e)
				this.form.video_url=''
			},

		},
		
	}
</script>	
<style>
	.edit_container .ql-editing{
		left: 0 !important;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>